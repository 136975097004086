:root {
  --primary-blue: #00b4d8;
  --dark-blue: #023e8a;
  --accent-green: #2d6a4f;
  --light-blue: #caf0f8;
  --off-white: #f8f9fa;
}

.song-journal {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background: var(--off-white);
}

h1 {
  text-align: center;
  color: var(--dark-blue);
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 30px;
  text-shadow: 1px 1px 0 var(--primary-blue);
}

.table-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background: white;
  padding: 15px;
  border-radius: 8px;
  border: 2px solid var(--primary-blue);
  box-shadow: 4px 4px 0 var(--dark-blue);
}

.archive-toggle {
  background: var(--light-blue);
  border: 2px solid var(--primary-blue);
  color: var(--dark-blue);
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  transition: all 0.2s;
}

.archive-toggle:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 0 var(--dark-blue);
}

.add-song-button {
  background: var(--accent-green);
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  box-shadow: 3px 3px 0 var(--dark-blue);
  transition: all 0.2s;
}

.add-song-button:hover {
  transform: translate(-2px, -2px);
  box-shadow: 5px 5px 0 var(--dark-blue);
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  margin-top: 20px;
}

th {
  background: var(--dark-blue);
  color: white;
  padding: 12px;
  text-align: left;
  cursor: pointer;
  border-radius: 4px;
}

td {
  background: white;
  padding: 12px;
  border-top: 2px solid var(--primary-blue);
  border-bottom: 2px solid var(--primary-blue);
}

td:first-child {
  border-left: 2px solid var(--primary-blue);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

td:last-child {
  border-right: 2px solid var(--primary-blue);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.date-column {
  width: 14%;
}

.album-art-column {
  width: 100px;
  text-align: center;
}

.preview-column {
  width: 50px;
  text-align: center;
}

.title-column {
  width: 15%;
}

.artist-column {
  width: 11%;
}

.album-art {
  width: 90px;
  height: 90px;
  object-fit: cover;
}

.preview-button {
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.preview-button:hover {
  border: 1px solid #333;
  border-radius: 12px;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
}

.icon-button {
  background: none;
  border: 1px solid #333;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 4px;
}

.icon-button:hover {
  background-color: #f0f0f0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border: 2px solid var(--primary-blue);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  border-bottom: 2px solid var(--primary-blue);
  padding-bottom: 15px;
}

.modal-header h2 {
  color: var(--dark-blue);
  margin: 0;
  font-size: 24px;
}

.close-button {
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: var(--dark-blue);
  transition: transform 0.2s;
}

.close-button:hover {
  transform: scale(1.1);
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-content input,
.modal-content textarea {
  padding: 5px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.save-button {
  background-color: var(--primary-blue);
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s;
}

.save-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.cancel-button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  padding: 10px 20px;
}

.spotify-search {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.spotify-search input {
  flex-grow: 1;
  padding: 10px;
  border: 2px solid var(--primary-blue);
  border-radius: 4px;
  font-size: 16px;
}

.spotify-search button {
  background: var(--accent-green);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s;
}

.spotify-search button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.spotify-result {
  background-color: #f8f9fa;
  padding: 15px;
  margin: 15px 0;
  border-radius: 8px;
  border: 2px solid var(--primary-blue);
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 20px;
  align-items: center;
}

.album-art-link {
  width: 80px;
  height: 80px;
  transition: opacity 0.2s;
}

.album-art-link:hover {
  opacity: 0.8;
}

.album-art-link img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.song-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.song-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
}

.song-details {
  color: #666;
  font-size: 0.9em;
  margin: 0;
}

.use-data-button {
  background: var(--accent-green);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s;
  height: fit-content;
}

.use-data-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.card-container {
  display: none;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .song-journal {
    padding: 10px;
  }

  h1 {
    font-size: 24px;
  }

  .table-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .archive-toggle,
  .add-song-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .table-container {
    overflow-x: auto;
  }

  table {
    font-size: 14px;
  }

  th, td {
    padding: 8px 4px;
  }

  .album-art {
    width: 60px;
    height: 60px;
  }

  .preview-button {
    font-size: 20px;
  }

  .date-column,
  .year-column {
    white-space: nowrap;
  }

  .description-column {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
  }

  .icon-button {
    margin: 2px 0;
    padding: 4px 8px;
  }

  .modal-content {
    width: 95%;
    padding: 15px;
  }

  .modal-content input,
  .modal-content textarea {
    font-size: 16px; /* Prevents zoom on input focus on iOS */
  }

  .modal-buttons {
    flex-direction: column-reverse;
  }

  .save-button,
  .cancel-button {
    width: 100%;
    margin-top: 10px;
  }
}


/* Additional adjustments for very small screens */
/* Existing styles remain unchanged */

/* ... (keep all the styles up to the media queries) ... */

/* Responsive styles */
@media screen and (max-width: 768px) {
  /* ... (keep existing styles for screens up to 768px) ... */
  
}

/* Card display for very small screens */
@media screen and (max-width: 480px) {
  .table-container {
    display: none;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .song-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
  }

  .song-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .song-card-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  .song-card-artist {
    font-size: 16px;
    color: #666;
    margin: 5px 0;
  }

  .song-card-details {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }

  .song-card-album-art {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  .song-card-info {
    flex-grow: 1;
  }

  .song-card-date,
  .song-card-year {
    font-size: 14px;
    color: #888;
  }

  .song-card-description-box {
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
  }
  
  .song-card-description {
    margin: 0;
    font-size: 0.9em;
    color: #333;
    line-height: 1.4;
  }

  .song-card-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .song-card .preview-button,
  .song-card .icon-button {
    font-size: 16px;
    padding: 5px 10px;
  }
}